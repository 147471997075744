<template>
    <div v-if="orderData">
        <div class="bg-white mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h1 class="h4 mb-0">Return order</h1>
                </div>
            </div>
            <div class="d-flex flex-wrap section-container mb-4 pb-5">
                <div v-for="(section, index) of sections" v-bind:key="section.name" class="section mt-3" :class="{ 'section-active': index <= currentSectionIndex }">
                    <span class="progress-circle">{{index + 1}}</span>
                    <div>
                        {{section.label}}
                    </div>
                </div>
            </div>
        </div>
        <form enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitOrder">
            <div v-if="currentSectionName == 'impressions'">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3">
                        <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(2)">
                            <strong class="label">Physical impressions</strong>
                            <br />
                            (Alginate, silicone, etc)
                        </button>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(1)">
                            <strong class="label">Digital impressions</strong>
                            <br />
                            (Intraoral scanner)
                        </button>
                    </div>                    
                    <div class="col-12 col-md-4 mb-3">
                        <button class="impression-type-button btn btn-primary btn-block" type="button" @click="impressionTypeButton(2)">
                            <strong class="label">No impressions</strong>
                            <br />
                            (Pre-cast or without impressions)
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="currentSectionName == 'scans'">
                <div class="mb-2">
                    <FileInput
                        label="Scans"
                        v-model="scansSection.scans"
                        :v="v$.scansSection.scans"
                        :allowedExtensions="['ply', 'stl']"
                    />
                </div>
                <div class="buttons">
                    <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                    <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                    <router-link class="btn btn-light" to="/orders">Cancel</router-link>
                </div>
            </div>
            <div v-if="currentSectionName == 'products'">
                <div class="mb-2">
                    <ProductInput
                        v-model="productsSection.products"
                        :v="v$.productsSection.products"
                        :service_level="orderData.standard.id"
                        :order_id="orderData.id"
                        :accountId="orderData.account.id"
                        :impressionType="this.impressionType"
                    />
                </div>
                <div class="buttons">
                    <button class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                    <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                    <router-link class="btn btn-light" to="/orders">Cancel</router-link>
                </div>
            </div>

            <div v-if="currentSectionName == 'instruction'">
                <div class="row">
                    <div class="col-6 col-md-4 mb-2">
                        <TextInput
                            label="Shade"
                            v-model="instructionSection.shade"
                            :v="v$.instructionSection.shade"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 mb-2">
                        <TextareaInput
                            label="Instructions"
                            v-model="instructionSection.note"
                            :v="v$.instructionSection.note"
                            rows="6"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="mb-2">
                        <AnnotationInput
                            label="Notations"
                            v-model="instructionSection.annotations"
                            :v="v$.instructionSection.annotations"
                        />
                    </div>
                </div>
                <div class="mb-2">
                    <FileInput
                        label="Photos"
                        v-model="instructionSection.photos"
                        :v="v$.instructionSection.photos"
                        :allowedExtensions="['jpg', 'jpeg', 'png', 'heic']"
                    />
                </div>
                <div class="buttons">
                    <button v-if="isAligner == true" class="btn btn-primary me-1" type="submit">Submit</button>
                    <button v-if="isAligner == false" class="btn btn-primary me-1" type="button" @click="nextButton">Next</button>
                    <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                    <router-link class="btn btn-light" to="/orders">Cancel</router-link>
                </div>
            </div>
            
            <div v-if="currentSectionName == 'delivery' && isAligner == false">
                <div class="row">
                    <div class="mb-2">
                        <DeliveryDateInput
                            label="Delivery date"
                            v-model="deliverySection.patient_date"
                            :v="v$.deliverySection.patient_date"
                            :account_id="this.orderData.account.id"
                            :order_type="this.impressionType"
                            :standard="this.orderData.standard.id"
                            :products="this.productsSection.products.map(x => x.id)"
                        />
                    </div>
                </div>
                <div class="buttons">
                    <button class="btn btn-primary me-1" type="submit">Submit</button>
                    <button class="btn btn-secondary me-1" type="button" @click="previousButton">Previous</button>
                    <router-link class="btn btn-light" to="/orders">Cancel</router-link>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped lang="scss">
    .section-container
    {
        border-bottom: 1px solid $swift-lighter-grey;
    }

    .section
    {
        flex: 1 0 0%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;

        .progress-circle
        {
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            border: 2px solid $swift-fresh-green;
            color: $swift-fresh-green;
            margin-bottom: 5px;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }
    }

    .section-active
    {
        .progress-circle
        {
            background-color: $swift-fresh-green;
            color: #FFFFFF;
        }
    }

    .buttons
    {
        padding-top: 20px;
    }

    .impression-type-button
    {
        padding: 40px;
        height: 100%;
        width: 100%;

        .label
        {
            font-size: 1.2em;
        }
    }

</style>

<script>
    import api from "@/services/api";
    import FileInput from '@/components/FileInput.vue';
    import TextInput from '@/components/TextInput.vue';
    import TextareaInput from '@/components/TextareaInput.vue';
    import DeliveryDateInput from '@/components/DeliveryDateInput.vue';
    import ProductInput from '@/components/ProductInput.vue';
    import AnnotationInput from '@/components/AnnotationInput.vue';
    import useVuelidate from '@vuelidate/core'
    import { required, requiredIf, minLength, maxLength, helpers } from '@vuelidate/validators'

    const todayDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setDate(today.getDate()+1)); 
    };

    const rangeEndDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setMonth(today.getMonth()+1)); 
    };
    
    const isNotWeekend = (value) => { 
        var day = new Date(value).getUTCDay();

        if([6,0].includes(day)) {
            return false;
        }

        return true;
    };

    const isAfterToday = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() >= todayDate().getTime();
    };

    const isBeforeRangeEnd = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() <= rangeEndDate().getTime()
    };

    const IMPRESSION_TYPE_DIGITAL = 1,
        IMPRESSION_TYPE_PHYSICAL = 2;

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {
                currentSectionIndex: 0,
                impressionType: null,
                orderData: null,
                scansSection: {
                    scans: [],
                },
                productsSection: {
                    products: [],
                },
                instructionSection: {
                    shade: '',
                    note: '',
                    annotations: [],
                    photos: [],
                },
                deliverySection: {
                    patient_date: '',
                },
            }
        },
        computed: {
            sections() {
                var sections = [
                    {
                        name: 'impressions',
                        label: 'Impressions',
                        validation: 'impressionsSection',
                    },
                    {
                        name: 'scans',
                        label: 'Scans',
                        validation: 'scansSection',
                    },
                    {
                        name: 'products',
                        label: 'Products',
                        validation: 'productsSection',
                    },
                    {
                        name: 'instruction',
                        label: 'Instructions',
                        validation: 'instructionSection',
                    },
                    {
                        name: 'delivery',
                        label: 'Delivery',
                        validation: 'deliverySection',
                    },
                ];

                if(this.impressionType == IMPRESSION_TYPE_PHYSICAL) // Physical
                {
                    sections = sections.filter(section => !['scans'].includes(section.name))
                }

                if (this.productsSection.products.filter(product => product.is_aligner).length > 0) {
                    sections = sections.filter(section => !['delivery'].includes(section.name));
                }

                return sections;
            },
            currentSection() {
                return this.sections[this.currentSectionIndex];
            },
            currentSectionName() {
                return this.currentSection.name;
            },
            isRequiresShade() {
                return this.productsSection.products.filter(product => product.requires_shade).length > 0;
            },
            isAligner() {
                return this.productsSection.products.filter(product => product.is_aligner).length > 0;
            }

        },
        validations () {
            return {
                scansSection: {
                    scans: { required },
                },
                productsSection: {
                    products: { required },
                },
                instructionSection: {
                    shade: { minLength: minLength(1), maxLength: maxLength(15), requiredIf: requiredIf(this.isRequiresShade) },
                    note: { maxLength: maxLength(1000) },
                    annotations: { maxLength: maxLength(100), },
                    photos: {  },
                },
                deliverySection: {
                    patient_date: {
                        required, 
                        isNotWeekend: helpers.withMessage('Date cannot be a weekend', isNotWeekend),
                        isAfterToday: helpers.withMessage('Date cannot be in the past', isAfterToday),
                        isBeforeRangeEnd: helpers.withMessage('Date must be in the next two months', isBeforeRangeEnd),
                    },
                },
            }
        },
        components: {            
            TextInput,
            FileInput,
            ProductInput,
            TextareaInput,
            DeliveryDateInput,
            AnnotationInput,
        },
        mounted () {
            this.fetchOrderData();
        },
        methods: {
            submitOrder: async function () {
                var currentSectionValidator = this.currentSection.validation;
                var isValid = await this.v$[currentSectionValidator].$validate();

                if(isValid)
                {
                    this.requestOrderCreation();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            orderRequestData: function()
            {
                var data = {
                    order_type: this.impressionType,
                    patient_date: this.deliverySection.patient_date,
                    note: this.instructionSection.note,
                    products: this.productsSection.products.map((product) => {
                        return {
                            id: product.id,
                            quantity: product.quantity,
                            material_id: product.material_id,
                        }
                    }),
                    is_aligner: this.isAligner,
                };

                // Supply scans data if digital impressions selected. 
                if(this.impressionType == IMPRESSION_TYPE_DIGITAL)
                {
                    data.scans = this.scansSection.scans.map(scan => scan.hash);
                }

                if(this.instructionSection.shade)
                {
                    data.shade = this.instructionSection.shade;
                }

                if(this.instructionSection.photos)
                {
                    data.files = this.instructionSection.photos.map(photo => photo.hash);
                }

                if(this.instructionSection.annotations)
                {
                    data.annotations = this.instructionSection.annotations.map((annotation) => {
                        var annotationReturn = {
                            file_id: annotation.hash,
                        };

                        if(annotation.text.length)
                        {
                            annotationReturn.text = annotation.text;
                        }

                        return annotationReturn;
                    });
                }

                return data;
            },
            requestOrderCreation: function () {
                api.post('/api/v1/orders/' + this.id + '/stage', this.orderRequestData())
                .then((response) => {
                    if(this.impressionType == IMPRESSION_TYPE_PHYSICAL)
                    {
                        this.$router.push({ name: 'OrderCreateSend', params: { 'id': response.data.id }});
                    }
                    else
                    {
                        this.$router.push({ name: 'PendingOrders' });
                    }
                })
                .catch(() => {
                    
                });
            },
            fetchOrderData: function() {   
                api
                .get('/api/v1/orders/' + this.id)
                .then((response) => {
                    this.orderData = response.data.data;
                    this.orderId = response.data.data.id;
                    this.instructionSection.shade = this.orderData.shade;
                })
                .catch(() => {
                    console.log('Catch order data error');
                });
            },
            nextButton: async function() {
                var currentSectionValidator = this.currentSection.validation;
                var isValid = await this.v$[currentSectionValidator].$validate();

                if(isValid)
                {
                    this.currentSectionIndex++;
                }
            },
            previousButton: async function() {
                this.currentSectionIndex--;

                if(this.currentSectionName === 'impressions')
                {
                    this.impressionType = null;
                }
            },
            impressionTypeButton: function(impressionType)
            {
                this.impressionType = impressionType;
                this.currentSectionIndex++;
            },
        },
    }

</script>
